import React from "react";
import Specialization from "./Specialization";
import {useState, useEffect} from "react";
import moment from 'moment';


async function getData() {
    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Bearer 214634320a378e57X0jxQpwQfbytD8qUVTsf0UcKU9sP9ahQchKwixh47nU3bdd2I4Kew4feUDk1UKe9"
    );
    myHeaders.append(
        "Cookie",
        "_s=e1loma6cp16ava3qbq1jpcjl6a6r379460gvjo7om3k46ivpgv9jk4qa1vh514ktq6pp82r5meqgpbs8c7tpu5fc4hng4saikca2gdfb346eobardefmptgdbi2pdepeuqi1vkss8us73faj013jcrn3j6k18vjb9400bqcv5iud664rkmi1eiob4gmb2efjkpq731go"
    );

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const tmp = await fetch(
        "https://app.kvery.io/query/api/8693db85ed9/1665556792IaRUml7bEIauIYdAA84ZLv",
        requestOptions
    ).then((response) => response.json());
    return await tmp;
}


const Body = () => {
    const [data, setData] = useState([]);
    const [currentTime, setCurrentTime] = useState("");
    const [startTimeOne, setStartTimeOne] = useState("");

    useEffect(() => {
        getData().then((response) => setData(response.response));
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment().format("HH:mm"));
            setStartTimeOne(moment().add(60, "minutes").format("HH:mm"));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    console.log(data);

    console.log(startTimeOne);

    const idopontok = [];

    data.forEach((doctor) => {
        doctor.availability.forEach((availability) => {
            const newDoctor = structuredClone(doctor);
            newDoctor.availability = availability;
            idopontok.push(newDoctor);
        });
    });

    console.log(idopontok)

    const availableDoctors = idopontok.filter(
        (doctor) =>
            doctor.availability.from <= startTimeOne &&
            currentTime <= doctor.availability.to
    );

    console.log(availableDoctors);

    const specializations = new Set();
    availableDoctors.forEach((doctor) =>
        specializations.add(doctor.specializations)
    );
    console.log(availableDoctors.teszt)
    return (
        <>
            <div className='wrapper'>
                <section className="cardholder">
                    {Array.from(specializations).map((specialization) => (
                        <Specialization
                            specialization={specialization}
                            doctors={availableDoctors.filter(
                                (doctor) => specialization === doctor.specializations
                            )}
                        />
                    ))}
                </section>
            </div>
        </>
    );
};

export default Body;
