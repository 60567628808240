import React from "react";
import Card from "./Card";
import DisabledCard from "./DisabledCard";

const Specialization = (props) => {
  return (
    <>
      {props.doctors.map((doctor) =>
        doctor.exclusion === true ? (
          <DisabledCard doctor={doctor} key={doctor.id} />
        ) : (
          <Card doctor={doctor} key={doctor.id} />
        )
      )}
    </>
  );
};

export default Specialization;
