import React, { useState, useEffect } from "react";
import Logo from "../logo.png";
import moment from 'moment';

const DateComponent = () => {
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY.MM.DD'));

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(moment().format('YYYY.MM.DD'));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return <div>{currentDate}</div>;
};


const ClockComponent = () => {
    const [currentTime, setCurrentTime] = useState(moment().format('HH:mm'));

    useEffect(() => {
        // A setInterval segítségével frissítjük az időt minden 1 másodpercenként.
        const interval = setInterval(() => {
            setCurrentTime(moment().format('HH:mm'));
        }, 1000);

        // A komponens törlésekor töröljük az intervalt is, hogy ne maradjon futni, ha már nem használjuk a komponenst.
        return () => clearInterval(interval);
    }, []);

    return <div>{currentTime}</div>;
};


const current = new Date();
const date = `${current.getFullYear()}.${(
  current.getMonth() + 1
).toLocaleString("en-US", {
  minimumIntegerDigits: 2,
  useGrouping: false,
})}.${current
  .getDate()
  .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}.`;


const TopBar = () => {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);
  return (
    <section className="topBar">
      <div className="topLeft">Rendelések</div>
      <div className="topCenter">
        <img className="logo" src={Logo}></img>
      </div>
      <div className="topRight">
          <img src="/calendar.svg" className="icon" alt="calendar" />
          <DateComponent />
          <img src="/clock.svg" className="icon" alt="clock" />
          <ClockComponent />
      </div>
    </section>
  );
};

export default TopBar;
