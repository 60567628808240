import React from "react";

const Card = (props) => {
    const formatFloor = (floor) => {
        if (floor === "Emelet") {
            return "Em.";
        } else if (floor === "Földszint") {
            return "Fsz.";
        } else {
            return floor;
        }
    };


  let instituteId = props.doctor.availability.institutionId;
  let institution = "";
  if (instituteId === 215) {
    institution = <p className="institute">TB</p>;
  }
  else {
    institution = <p className="institute">MAGÁN</p>;
  }

  return (
    <div className="card_box">
      <div className="card_left_wrapper">
        <div className="place_floor">
            {formatFloor(props.doctor.availability.floor)}
        </div>
        <div className="place_number">
          {props.doctor.availability.number}
        </div>
      </div>
      <div className="card_right_wrapper">
      <div className="card_name">
        <p className="doctor_name">{props.doctor.name}</p>
        <p className="specialization-name">{props.doctor.availability.customName}</p>
        <>{institution}</>
        <div className="card_inner">
          <div className="card_time">
            {props.doctor.availability.from} -{" "}
            {props.doctor.availability.to}
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Card;
