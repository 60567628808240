import React from "react";

const DisabledCard = (props) => {
  return (
    <div className="card_box">
      <div className="card_name">
        <h2>{props.doctor.name}</h2>
        <p className="specialization-name">{props.doctor.specializations}</p>
        <div className="card_inner">
          <div className="out_of_office">Szabadság</div>
        </div>
      </div>
    </div>
  );
};

export default DisabledCard;
