import "./App.css";
import TopBar from "./components/TopBar";
import Body from "./components/Body";

function App() {
  return (
    <>
      <TopBar />
      <Body />
    </>
  );
}

export default App;
